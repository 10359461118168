const config = {
    window: null,
    document: null,
    htmlBody: null,
    html: null,
    body: null,

    log(msg, group, type) {
        switch (group) {
            default:
                if (type === undefined || type === 'log') {
                    console.log(msg);
                } else if (type === 'error') {
                    console.error(msg);
                } else {
                    console.warn(msg);
                }
                break;
        }
    },

    bind() {
        this.window = $(window);
        this.document = $(document);
        this.htmlBody = $('html, body');
        this.html = $('html');
        this.body = $('body');
    },
};

export default config;

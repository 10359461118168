// Import the config
import config from './config';

// Import libraries
import 'bootstrap';

// Import all components
import Header from './components/header';
import Accordion from './components/accordion';

// Import all plugins

(function ($) {
    'use strict';


    /**
     * @type {{init(): void, load()}}
     */
    const plugins = {
        init() {

        },
    };


    /**
     * Wrapper for all functions
     * @type {{init: init}}
     */
    const fn = {
        init() {
            Header.init();
            Accordion.init();
        },

        load() {

        },

        resize() {

        },
    };


    /**
     * Initializations on document ready
     */
    $(document).ready(function () {
        // Bind the config values
        config.bind();

        // Call the plugin initialization
        plugins.init();

        // Call the function initialization
        fn.init();
    });


    /**
     * Initializations on window load
     */
    $(window).on('load', function () {
        fn.load();
    });


    /**
     * Initializations on smartresize event
     */
    // $(window).smartresize(function () {
    //     fn.resize();
    // });
})(jQuery);

// Header collapse
const Header = {
    subnavButton: null,
    header: null,
    stickyOffset: null,
    instances: [],

    binding() {
        // When the button exsist and the user clicks on the burger, toggel menu
        if (this.subnavButton) {
            this.subnavButton.on('click', function () {
                if ( $('.logo__secoundary:hidden').length == 0 ) {
                    $('.logo__secoundary').hide();
                    $('.header__nav__secoundary').hide();
                    $(this).removeClass('is-active');
                } else {
                    $('.logo__secoundary').show();
                    $('.header__nav__secoundary').show();
                    $(this).addClass('is-active');
                };
            });
        }

        // When the button exsist and the user clicks on the burger, toggel menu
        if (this.mobilnavButton) {
            this.mobilnavButton.on('click', function () {
                $('.logo__secoundary').hide();
                if ( $(this).hasClass('is-active') ) {
                    $('.header__main').removeClass('mobile--active');
                    $(this).removeClass('is-active');
                } else {
                    $('.header__main').addClass('mobile--active');
                    $(this).addClass('is-active');
                };
            });
        }

        // When the header exsist and the user scrolls the page, execute sticky method
        if (this.header) {
            window.onscroll = () => {
                this.sticky();
            };
        }

    },

    init() {
        // Get the header burger
        this.subnavButton = $('.hamburger__toogle_subnav');
        this.mobilnavButton = $('.hamburger__toogle_mobilnav');

        // Get the header
        this.header = document.getElementById('header');

        // Get the offset position of the navbar
        this.stickyOffset = this.header.offsetTop;
        this.stickyOffset += this.header.offsetHeight;

        // bind bindings
        this.binding();
    },

    // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
    sticky() {
        if (window.pageYOffset > this.stickyOffset) {
            if ( $('.logo__secoundary:hidden').length == 0 ) {
                this.subnavButton.trigger('click');
            }
            header.classList.add('sticky');
            $('.header__main').removeClass('mobile--active');
            this.mobilnavButton.removeClass('is-active');
            this.stickyOffset = this.header.offsetTop;
        } else {
            header.classList.remove('sticky');
            this.stickyOffset = this.header.offsetTop;
            this.stickyOffset += this.header.offsetHeight;
        }

        if (window.innerWidth >= 768) {
        }

    },
};

export default Header;

// Header collapse
const Accordion = {
    accordeon: null,
    instances: [],

    binding() {
        // When the accordeon exsist close it
        if (this.accordeon) {
            this.accordeon.trigger('click');
        }

    },

    init() {
        // Get the accordion active titles
        this.accordeon = $('.elementor-tab-title.elementor-active');

        // bind bindings
        this.binding();
    },
};

export default Accordion;
